import React from 'react';
import { Helmet } from 'react-helmet';

import SiteHeader from '../../../components/SiteHeader/type4';
import SectionHeader from '../../../components/SectionHeader';
import KinetikFeature from '../../../components/KinetikFeature';
import ConsultantFeature from '../../../components/ConsultantFeature';
import Footer from '../../../components/Footer';
import ProjectFeature from '../../../components/ProjectFeature';
import CTA from '../../../components/CTA';
import SiteNavigation from '../../../components/SiteHeader/navigation';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


import LogoCloud from '../../../components/LogoCloud';

const tiers = [
    {
        name: 'Oversikt',
        href: '#',
        priceMonthly: 49,
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
        features: [
            'Pariatur quod similique',
            'Sapiente libero doloribus modi nostrum',
            'Vel ipsa esse repudiandae excepturi',
            'Itaque cupiditate adipisci quibusdam',
        ],
    },
    {
        name: 'Varsling',
        href: '#',
        priceMonthly: 79,
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
        features: [
            'Pariatur quod similique',
            'Sapiente libero doloribus modi nostrum',
            'Vel ipsa esse repudiandae excepturi',
            'Itaque cupiditate adipisci quibusdam',
        ],
    },
    {
        name: 'Nettops',
        href: '#',
        priceMonthly: 79,
        description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
        features: [
            'Pariatur quod similique',
            'Sapiente libero doloribus modi nostrum',
            'Vel ipsa esse repudiandae excepturi',
            'Itaque cupiditate adipisci quibusdam',
        ],
    },
]


import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image';

function Consulting({ props }) {
    return (
        <div className="bg-gray-50">
            <Helmet>
                <title>Convivial - Konsulent</title>
                <link rel="canonical" href="https://convivial.no/tjenester/konsulent" />
                <meta name="description" content="Joviale &amp; dyktige konsulenter" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <main>
                <SiteNavigation bgColor="bg-white" />

                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 mb-16">
                    <div className="sm:text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                            <span className="block">Joviale &&nbsp;dyktige</span>{' '}
                            <span className="block text-convivial">Konsulenter</span>
                        </h1>
                        <p className="mt-3 max-w-md sm:mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Vi bistår kunder med spennende oppgaver. Brenner du inne med en god ide eller et problem du trenger å få løst? Ta kontakt med oss for en uforpliktende&nbsp;prat.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-50">
                    <div className="relative overflow-hidden">
                        <div className="relative">
                            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                                <div className="flex-1" />
                                <div className="flex-1 w-full bg-white" />
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-6">
                                <StaticImage
                                    // height={500}
                                    className="relative rounded-lg w-full shadow-md h-96"
                                    src="../../../../static/Hero/BannerImage68.jpg"
                                    alt="N" />

                            </div>
                        </div>
                    </div>
                    <LogoCloud bgColor="bg-white" product={['generic']} />
                </div>

                <SectionHeader
                    title=""
                    header="Vi kan hjelpe med"
                    description="Vi hjelper gode kunder med spennende oppgaver. Brenner du inne med en god ide eller et problem du trenger å løse ta&nbsp;kontakt."
                    backgroundColor="bg-white"
                />

                {/* Consultant Feature Sections */}
                <ConsultantFeature />


                <SectionHeader
                    title="Prosjekter"
                    header="Noe av det vi har laget for&nbsp;andre."
                    description="Her er noe av det vi har syslet med den siste tiden som en del av konsulentleveransen&nbsp;vår."
                    backgroundColor="bg-gray-100"
                />

                <ProjectFeature />

                <CTA
                    demo={false}
                    header="Kan vi hjelpe deg?"
                    subHeader="Ta kontakt!"
                    bgColor="bg-gray-100"
                    contactButtonColor="bg-convivial"
                    demoButtonColor="text-convivial"
                />

            </main>
            <Footer />
        </div>
    );
}

export default Consulting;
